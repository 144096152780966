module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://cms.johnzambrano.dev","contentApiKey":"205233a2f1793bd667b202593b"},"production":{"apiUrl":"https://cms.johnzambrano.dev","contentApiKey":"205233a2f1793bd667b202593b"}},"siteConfig":{"siteUrl":"https://johnzambrano.dev","infiniteScroll":true,"postsPerPage":8,"siteTitleMeta":"John Zambrano - Full Stack Web Developer","siteDescriptionMeta":"Hello, I'm John. I'm a full-stack web developer. I build production-ready, data-driven applications.","shortTitle":"Here's Johnny","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":false,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":true,"overrideOS":true},
    }]
